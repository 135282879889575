var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "div",
        { staticClass: "table-page-search-wrapper" },
        [
          _c(
            "a-form",
            {
              attrs: { layout: "inline" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.searchQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "a-form-item",
                        [
                          _c("a-input-search", {
                            attrs: { placeholder: "请输入标题" },
                            model: {
                              value: _vm.queryParam.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParam, "title", $$v)
                              },
                              expression: "queryParam.title",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    {
                      staticStyle: {
                        display: "flex",
                        float: "right",
                        "justify-content": "end",
                      },
                      attrs: { span: 4 },
                    },
                    [
                      _vm.radioValue == "1"
                        ? _c(
                            "a-button",
                            {
                              attrs: { type: "primary", icon: "book" },
                              on: { click: _vm.readAll },
                            },
                            [_vm._v("全标已读")]
                          )
                        : _c(
                            "a-button",
                            {
                              attrs: { type: "primary", icon: "book" },
                              on: { click: _vm.clearReadAll },
                            },
                            [_vm._v("清空已读")]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "padding-top": "10px", "padding-bottom": "20px" } },
        [
          _c(
            "a-radio-group",
            {
              model: {
                value: _vm.radioValue,
                callback: function ($$v) {
                  _vm.radioValue = $$v
                },
                expression: "radioValue",
              },
            },
            [
              _c("a-radio", { attrs: { value: "1" } }, [_vm._v("未读")]),
              _c("a-radio", { attrs: { value: "2" } }, [_vm._v("已读")]),
            ],
            1
          ),
        ],
        1
      ),
      _c("a-table", {
        ref: "table",
        attrs: {
          size: "default",
          rowKey: "id",
          columns: _vm.columns,
          dataSource: _vm.msgTableData,
          pagination: false,
          loading: _vm.loading,
          customRow: _vm.customRow,
          expandedRowKeys: _vm.expandedRowKeys,
          scroll: { x: "max-content", y: "calc(100vh - 446px)" },
        },
        on: {
          expand: _vm.expandTable,
          expandedRowsChange: _vm.expandedRowsChange,
          change: _vm.handleTableChange,
        },
        scopedSlots: _vm._u([
          {
            key: "readFlag",
            fn: function (text, record) {
              return [
                record.children && record.total > 50 && record.isOpen
                  ? _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        _c("a-pagination", {
                          attrs: {
                            size: "small",
                            showLessItems: true,
                            "default-current": 1,
                            defaultPageSize: 50,
                            total: record.total - 0,
                          },
                          on: {
                            change: (page, pageSize) => {
                              _vm.treeTablePageChange(page, pageSize, record)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : record.readFlag != "hasChild"
                  ? _c("div", [
                      _vm._v(
                        "\n        " +
                          _vm._s(record.readFlag == "1" ? "已读" : "未读") +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("show-announcement", {
        ref: "ShowAnnouncement",
        on: { openRemark: _vm.openRemark },
      }),
      _c("dynamic-notice", {
        ref: "showDynamNotice",
        attrs: { path: _vm.openPath, formData: _vm.formData },
      }),
      _c(
        "a-modal",
        {
          attrs: {
            title: `【备注  ${_vm.taskDetail.productionName}-${_vm.taskDetail.chapterOrder}】`,
            placement: "right",
            width: 700,
            footer: null,
            wrapClassName: "drawer-layout",
            visible: _vm.drawerVisible,
          },
          on: { cancel: _vm.onClose },
        },
        [
          _c("remark", {
            ref: "remark",
            attrs: {
              height: "500px",
              isMyTask: true,
              taskDetail: _vm.taskDetail,
              params: _vm.taskDetail,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }